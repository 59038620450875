<template>
	
	<div id="contactScroll">
		
		<a class="phone" href="phone:0048509488466" title="Zadzwoń do nas">+48&nbsp;509&nbsp;488&nbsp;466</a>
		<a class="email" href="mailto:biuro@netergo.com" title="Napisz do nas">biuro@netergo.com</a>

	</div>

</template>


<script>
	



</script>



<style lang="scss">
	
	#contactScroll {

		position: fixed;
		right: 0;
		top: 50%;
		width: 400px;
		height: auto;
		background-color: #fff;
		transform: rotate(90deg) translateY(-130px);
		display: flex;
		align-items: center;
		z-index: 999;

		.phone, .email {
			display: block;
			text-align: center;
			width: 50%;
			text-decoration: none;
			@include font(1em, 700, 2.2, $text-font-1, $text-color);
		}

		.email {
			color: #fff;
			background-color: $lead-color;
		}

		@media screen and (max-width:1400px) {

			display: none;

		}

	}


</style>