import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { MotionPlugin } from '@vueuse/motion';
import VueKinesis from "vue-kinesis";
import VueGtag from "vue-gtag";


import '@/assets/scss/normalize.scss';

const app = createApp(App);

app.use(router);
app.use(MotionPlugin);
app.use(VueKinesis);
app.use(VueGtag,{
	config: { id: "G-EG4LV4BNEQ" },
	globalObjectName: 'gtag',
	router,
	enabled: true,
});

app.provide('gtag', app.config.globalProperties.$gtag);

app.mount('#app');


