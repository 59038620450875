<template>

  <svg class="logoNetergo" version="1.1" xmlns="http://www.w3.org/2000/svg"  width="198.425px" height="52.954px" viewBox="0 0 198.425 52.954">

    <g>

      <path fill="#231F20" d="M29.749,34.221c-0.393-0.333-0.588-0.791-0.588-1.374V17.236c0-2.831-0.805-4.996-2.415-6.494
      c-1.609-1.5-3.88-2.249-6.811-2.249c-0.578,0-1.094,0.146-1.547,0.438c-1.28,0.749-2.549,1.467-3.808,2.154
      c-1.259,0.687-2.507,1.322-3.746,1.904h-0.34c0.029-0.765,0.083-1.503,0.186-2.186c0.144-0.957,0.361-1.664,0.65-2.123l-0.619-0.5
      C9.513,8.598,8.399,8.94,7.368,9.211C6.335,9.482,5.252,9.71,4.117,9.898C2.982,10.085,1.61,10.304,0,10.554v1.125l1.981,0.499
      c0.536,0.125,0.949,0.333,1.238,0.625c0.288,0.291,0.433,0.874,0.433,1.748v17.86c0,0.583-0.134,1.093-0.402,1.53
      c-0.269,0.437-0.692,0.76-1.27,0.968L0,35.657v1.063h14.55v-1.063l-1.981-0.749c-0.331-0.125-0.691-0.353-1.083-0.687
      c-0.393-0.333-0.588-0.791-0.588-1.374V19.546c0-1.707,0.484-3.112,1.455-4.215c0.97-1.103,2.342-1.656,4.117-1.656
      c1.774,0,3.126,0.49,4.056,1.468c0.928,0.978,1.393,2.383,1.393,4.214V32.41c0,0.583-0.135,1.093-0.403,1.53
      c-0.269,0.437-0.691,0.76-1.269,0.968l-1.981,0.749v1.063h14.549v-1.063l-1.981-0.749C30.502,34.783,30.141,34.555,29.749,34.221z
      M52.192,18.921l-4.014,0.562v2.248h10.638c0.496,0,0.856-0.156,1.083-0.468c0.227-0.313,0.341-0.677,0.341-1.094
      c0-2.206-0.455-4.193-1.362-5.962c-0.908-1.77-2.177-3.164-3.807-4.185c-1.631-1.02-3.54-1.53-5.727-1.53
      c-2.724,0-5.17,0.624-7.336,1.873c-2.167,1.249-3.88,2.977-5.139,5.184c-1.259,2.207-1.888,4.768-1.888,7.68
      c0,2.583,0.588,4.934,1.765,7.057c1.176,2.124,2.786,3.81,4.829,5.059c2.043,1.249,4.385,1.873,7.027,1.873
      c2.229,0,4.313-0.541,6.253-1.623c1.939-1.083,3.735-2.769,5.386-5.059l-1.052-0.998c-2.436,2.082-4.871,3.122-7.306,3.122
      c-1.61,0-3.096-0.416-4.458-1.249c-1.362-0.832-2.456-2.061-3.281-3.684c-0.826-1.624-1.238-3.601-1.238-5.933
      c0-3.373,0.536-6.099,1.609-8.181c1.073-2.081,2.559-3.123,4.458-3.123c1.238,0,2.229,0.636,2.972,1.906
      c0.743,1.27,1.114,3.008,1.114,5.214C53.058,18.485,52.769,18.921,52.192,18.921z M78.907,32.66
      c-0.765,0.292-1.436,0.438-2.013,0.438c-0.784,0-1.424-0.26-1.919-0.78c-0.496-0.521-0.743-1.572-0.743-3.154V11.178h6.501
      l0.619-2.061h-7.12V0h-1.61c-0.537,2.832-1.569,5.027-3.096,6.589c-1.527,1.561-3.694,2.528-6.501,2.904v1.685h3.963v18.485
      c0,5.038,2.063,7.556,6.191,7.556c1.155,0,2.517-0.343,4.086-1.03c1.568-0.688,3.178-1.696,4.829-3.029l-0.867-1.624
      C80.444,31.994,79.669,32.369,78.907,32.66z M101.782,18.921l-4.014,0.562v2.248h10.638c0.495,0,0.856-0.156,1.083-0.468
      c0.227-0.313,0.341-0.677,0.341-1.094c0-2.206-0.455-4.193-1.363-5.962s-2.177-3.164-3.808-4.185
      c-1.631-1.02-3.539-1.53-5.727-1.53c-2.724,0-5.17,0.624-7.336,1.873c-2.167,1.249-3.88,2.977-5.139,5.184
      c-1.259,2.207-1.888,4.768-1.888,7.68c0,2.583,0.588,4.934,1.765,7.057c1.176,2.124,2.786,3.81,4.83,5.059
      c2.042,1.249,4.385,1.873,7.026,1.873c2.229,0,4.313-0.541,6.253-1.623c1.939-1.083,3.735-2.769,5.387-5.059l-1.053-0.998
      c-2.435,2.082-4.87,3.122-7.305,3.122c-1.61,0-3.096-0.416-4.458-1.249c-1.362-0.832-2.456-2.061-3.281-3.684
      c-0.826-1.624-1.238-3.601-1.238-5.933c0-3.373,0.536-6.099,1.609-8.181c1.073-2.081,2.559-3.123,4.458-3.123
      c1.238,0,2.228,0.636,2.972,1.906c0.743,1.27,1.114,3.008,1.114,5.214C102.648,18.485,102.359,18.921,101.782,18.921z
      M133.418,8.556c-0.412,0-0.846,0.062-1.299,0.187c-0.455,0.125-1.012,0.397-1.671,0.812l-6.744,4.23
      c0.006-1.068,0.073-2.064,0.212-2.981c0.144-0.957,0.361-1.664,0.649-2.123l-0.619-0.5c-1.197,0.417-2.311,0.76-3.343,1.031
      c-1.032,0.271-2.115,0.499-3.25,0.687c-1.136,0.187-2.508,0.406-4.117,0.655v1.125l1.98,0.499c0.536,0.125,0.95,0.333,1.238,0.625
      c0.288,0.291,0.434,0.874,0.434,1.748v17.86c0,0.667-0.124,1.186-0.371,1.562c-0.248,0.375-0.682,0.687-1.301,0.937l-1.98,0.749
      v1.063h15.168v-1.063l-2.291-0.749c-0.662-0.25-1.157-0.541-1.486-0.875c-0.331-0.333-0.496-0.728-0.496-1.186V20.545
      c0-1.874,0.217-3.279,0.65-4.215c0.434-0.937,0.918-1.55,1.455-1.842c0.536-0.291,0.99-0.437,1.362-0.437
      c0.412,0,0.783,0.156,1.115,0.468c0.33,0.313,0.639,0.635,0.928,0.968c0.412,0.417,0.898,0.812,1.455,1.187
      c0.557,0.375,1.228,0.562,2.013,0.562c1.32,0,2.382-0.427,3.188-1.28c0.805-0.854,1.208-1.8,1.208-2.842
      c0-1.29-0.424-2.372-1.269-3.247C135.389,8.992,134.45,8.556,133.418,8.556z M164.126,33.347
      c-1.816-0.541-3.713-0.812-5.695-0.812c-1.281,0-2.704,0.042-4.272,0.125c-1.569,0.084-2.808,0.125-3.715,0.125
      c-1.032,0-1.879-0.166-2.539-0.499c-0.66-0.333-0.991-0.832-0.991-1.499c0-0.666,0.548-1.207,1.642-1.624
      c1.093-0.415,2.589-0.624,4.489-0.624c2.269,0,4.209-0.438,5.82-1.312c1.609-0.874,2.848-2.039,3.714-3.496
      c0.868-1.457,1.3-3.06,1.3-4.809c0-1.124-0.124-2.102-0.372-2.935s-0.66-1.77-1.238-2.81v-0.375
      c0.867,0.375,1.713,0.562,2.539,0.562c1.278,0,2.352-0.5,3.219-1.5c0.867-0.999,1.3-2.685,1.3-5.058
      c0-1.082-0.278-1.998-0.836-2.748c-0.556-0.75-1.31-1.125-2.259-1.125c-0.703,0-1.322,0.209-1.857,0.625
      c-0.537,0.416-0.805,1.083-0.805,1.998c0,0.417,0.081,0.791,0.248,1.124c0.165,0.334,0.35,0.667,0.557,1
      c0.248,0.374,0.433,0.739,0.557,1.093c0.124,0.354,0.186,0.677,0.186,0.968c0,0.749-0.599,1.124-1.795,1.124
      c-0.703,0-1.415-0.104-2.137-0.313c-0.722-0.208-1.476-0.437-2.259-0.687c-0.908-0.333-1.868-0.634-2.878-0.905
      c-1.013-0.271-2.117-0.406-3.314-0.406c-3.385,0-6.108,0.937-8.171,2.81c-2.065,1.874-3.096,4.289-3.096,7.244
      c0,1.499,0.423,3.05,1.27,4.652c0.845,1.603,2.136,2.883,3.87,3.84v0.313c-0.702,0.958-1.498,1.916-2.384,2.872
      c-0.888,0.959-1.91,1.853-3.064,2.686c-0.166,0.125-0.279,0.24-0.341,0.343c-0.062,0.104-0.093,0.219-0.093,0.344
      c0,1.916,0.95,3.372,2.849,4.371c1.897,0.999,4.292,1.499,7.181,1.499c1.197,0,2.394-0.042,3.591-0.125
      c1.196-0.083,2.373-0.125,3.53-0.125c2.188,0,4.002,0.438,5.448,1.312c1.444,0.874,2.166,2.101,2.166,3.684
      c0,1.79-0.981,3.164-2.941,4.121c-1.96,0.958-4.405,1.437-7.335,1.437c-3.056,0-5.335-0.583-6.842-1.75
      c-1.507-1.166-2.258-2.435-2.258-3.809c0-1.418,0.013-1.96,0.026-2.144c-0.645-0.459-1.593-0.87-2.664-1.339
      c-1.547,0.541-1.583,0.486-2.594,1.484c-1.012,1-1.517,2.187-1.517,3.561c0,1.664,0.65,3.017,1.95,4.058
      c1.301,1.04,2.982,1.811,5.047,2.312c2.063,0.499,4.209,0.749,6.438,0.749c3.26,0,6.211-0.427,8.854-1.279
      c2.64-0.854,4.746-2.145,6.314-3.872c1.569-1.729,2.353-3.903,2.353-6.526c0-2.208-0.579-3.945-1.734-5.215
      C167.428,34.793,165.942,33.889,164.126,33.347z M149.948,12.614c0.661-1.165,1.589-1.748,2.786-1.748
      c1.321,0,2.322,0.667,3.003,1.998c0.682,1.333,1.021,3.289,1.021,5.87c0,2.54-0.331,4.402-0.99,5.588
      c-0.661,1.187-1.59,1.781-2.786,1.781c-1.322,0-2.322-0.677-3.003-2.03c-0.681-1.353-1.022-3.361-1.022-6.026
      C148.958,15.591,149.289,13.78,149.948,12.614z M146.137,42.131c0.009,0.006,0.02,0.012,0.029,0.018
      C146.166,42.149,146.151,41.933,146.137,42.131z M196.692,15.425c-1.157-2.124-2.786-3.798-4.891-5.027
      c-2.106-1.228-4.562-1.841-7.368-1.841c-2.766,0-5.201,0.614-7.306,1.841c-2.105,1.229-3.736,2.936-4.892,5.121
      c-1.155,2.186-1.733,4.715-1.733,7.587c0,2.665,0.578,5.059,1.733,7.181c1.156,2.124,2.786,3.81,4.892,5.059
      c2.105,1.249,4.54,1.873,7.306,1.873c2.806,0,5.262-0.624,7.368-1.873c2.104-1.249,3.734-2.977,4.891-5.184
      c1.154-2.206,1.733-4.724,1.733-7.556C198.425,19.942,197.847,17.547,196.692,15.425z M188.984,32.317
      c-1.012,1.935-2.507,2.903-4.488,2.903c-2.023,0-3.54-1.082-4.551-3.247c-1.013-2.165-1.518-5.349-1.518-9.555
      c0-4.08,0.505-7.077,1.518-8.992c1.011-1.915,2.486-2.873,4.426-2.873c2.063,0,3.602,1.073,4.613,3.216
      c1.011,2.145,1.517,5.318,1.517,9.523C190.501,27.374,189.995,30.381,188.984,32.317z"/>

    </g>

  </svg>

</template>

<script>

export default {
  name: 'LogoNetergo',
};

</script>

<style lang="scss" scoped>

  .logoNetergo {

    width: 140px;
    height: auto;
    margin-top: 7px;

    path {

      fill: #fff !important;

    }

    @media screen and (max-width:600px) {

      & {

        width: 100px;
        margin-top: 10px;    

        path {

          fill: $lead-color !important;

        } 

      }

    }

  }

  .fixed .logoNetergo {

    path {

      fill: $lead-color !important;

    }    

  }

  footer {

    .logoNetergo {

      path {

        fill: $text-color !important;

      }

    }

  }

</style>