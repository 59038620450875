<template>

  <footer>
    
    <div class="wrapper">
      
      <div id="footerContact" class="scrollAnim">
        
        <LogoNetergo />

        <a class="phone" href="tel:00882059691" title="Połącz się z konsultantem">+48 882 059 691</a>
        <span>infolinia czynna: pn.-pt.: 9:00-18:00</span>
        <a class="email" href="mainto:biuro@netergo.com" title="Napisz do nas">biuro@netergo.com</a>

      </div>

      <div id="footerNav">

        <ul>
          <li><router-link to="/oferta/aktualizacja-stron-www" title="Zobacz ofertę wsparcia i aktualizacji stron www">aktualizacja stron www</router-link></li>
          <li><router-link to="/oferta/strony-www" title="Zobacz ofertę projektowania i realizacji stron www">strony internetowe</router-link></li>
          <li><router-link to="/realizacje" title="Zobacz nasze realizacje">realizacje</router-link></li>
          <li><router-link to="/kontakt" title="Masz pytania? Skontaktuj się z nami">kontakt</router-link></li>
        </ul>

      </div>

    </div>

    <ul id="footnote" class="wrapper">
    
      <li class="copyrights">&copy; 2021-{{ currentYear }} Netergo</li>
      <li class="privacy-policy">
        <router-link to="/polityka-prywatnosci">Polityka&nbsp;prywatności</router-link>
      </li>
    
    </ul>    

  </footer>

</template>


<script>

import LogoNetergo from '@/components/LogoNetergo.vue';

export default {

  name: 'PageFooter',

  data() {
    return {

      currentYear: new Date().getFullYear(),

    }
  },  

  components: {
    LogoNetergo,
  },

}


</script>


<style lang="scss" scoped>
  
  @keyframes footerFromLeft {

    0% {
      opacity: 0;
      transform: translateX(-50px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }

  }

  @keyframes footerFromRight {

    0% {
      opacity: 0;
      transform: translateX(50px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }

  }

  @keyframes footerFromBottom {

    0% {
      opacity: 0;
      transform: translateY(30px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }

  }

  footer {

    max-width: $layout-max-width;
    width: 96%;
    margin: 0 auto;
    min-width: $layout-min-width;
    padding-top: 10vh;
    position: relative;
    z-index: 996;

    .wrapper:first-child {
      
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 8vh;
      align-items: center;    

    }

    #footerContact {

      padding-right: 7%;
      position: relative;
      opacity: 1;

      &.animate {
        animation: footerFromLeft 1s forwards;
      }

      * {

        font-family: $text-font-3;
        color: $text-color;       
        font-weight: 400;
        line-height: 1.5;

      }

      svg {

        float: none;
        display: block;
        width: 200px;
        height: auto;

      }

      .phone {

        font-size: 28px;
        margin-top: 1em;
        display: block;

      }

      .email {

        font-size: 21px;
        display: block;

      }

      span {

        font-family: $text-font-1;
        font-weight: 300;
        font-size: 13px;
        margin-bottom: 1em;
        display: block;

      }

      a {

        text-decoration: none;

      }

    }

    #footerNav {

      padding-left: 7%;
      border-left: 2px solid #000;
      opacity: 1;

      ul {

        margin: 0;
        padding:0;
        list-style-type: none;

        li {

          margin-bottom: 0.7em;


          &, a {

            text-decoration: none;
            font-family: $text-font-1;
            font-size: 1.1em;
            line-height: 1.4;
            color: $text-color;
            font-weight: 600;

          }

          a {

            position: relative;

          }

          a::before {

            background: rgba($lead-color-rgb,1);
            width: 2px;
            height: 100%;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
            -moz-transition: opacity 0.2s, -moz-transform 0.2s;
            transition: opacity 0.2s, transform 0.2s;
            -webkit-transform: translateX(-20px);
            -moz-transform: translateX(-20px);
            transform: translateX(-20px);           

          }

          a:hover::before {
          
            opacity: 1;
            -webkit-transform: translateX(-10px);
            -moz-transform: translateX(-10px);
            transform: translateX(-10px);           

          }

        }

      }

    }

    #footnote {

      text-align: center;
      padding: 1.5em 0;
      list-style-type: none;
      font-size: 0.9em;

      * {

        font-family: $text-font-1;
        font-weight: 400;
        color: $text-color;
        line-height: 1.7;
        font-size: 1em;

      }

      li {

        display: inline;
        margin:0 5px;

      }

    }


  }



  @media screen and (max-width:800px) { 

    footer {

      .wrapper:first-child {

        flex-wrap: wrap;

      }

    }

  }

  @media screen and (max-width:600px) {

    footer {

      width: 92%;

    }

  }

  @media screen and (max-width:550px) { 

    footer {

      #footerContact {

        width: 100%;
        padding-right: 0;

        &, * {
          text-align: center;
        }

        svg {
          display: inline;
        }

      }

      #footerNav {

        padding-left: 0;
        border-left: 0;
        padding: 50px 0 0 0;
        text-align: center;

      }


    }

  }



</style>