<template>

  <div :class='viewId'>
    
    <PageHeader />

    <main>

      <!-- <router-view v-slot="{ Component }">
              <transition name="fade" mode="out-in">
                <component :is="Component" :key="$route.path" />
              </transition>
            </router-view> -->
      <router-view />

    </main>

    <PageFooter />

    <CookiesConsent />

  </div>

  <ContactScroll />

</template>


<script>

import PageHeader from '@/components/PageHeader.vue';
import PageFooter from '@/components/PageFooter.vue';
import CookiesConsent from '@/components/CookiesConsent.vue';
import ContactScroll from '@/components/ContactScroll.vue';

export default {

  name: 'App',

  components: {
    PageHeader,
    PageFooter,
    CookiesConsent,
    ContactScroll,
  },

  data() {
    return {

      viewId: '',

    }
  },

  watch: {
    '$route': function(){

      this.viewId = this.$route.name;

    },
  }, 

};

</script>

<style lang="scss">

  @import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200;300;400;500;600;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');


  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.35s;
  }

  
  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }  

  body {

    background-color: $background-color;
    font-family: $text-font-1;

    main {

      max-width: $layout-max-width;
      width: 96%;
      margin: 0 auto;
      min-height: 100vh;

    }

    .wrapper {

      max-width: 1340px;
      padding-left: 40px;
      padding-right: 40px;
      margin: 0 auto;

    }

    .hero { opacity: 0; }

    h1 {

      @include font(2em, 600, 1.3, $text-font-1, $text-color);
      text-transform: uppercase;
      letter-spacing: 0.25em;
      margin: 4em 0 3em;
      text-align: center;  

    }

  }

  .brackets-link {

    padding-left: 2em;
    padding-right: 2em;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    @include font(0.9em, 600, 1.3, $text-font-1, $text-color);
    text-decoration: none;

    &::before {
      content: url('@/assets/images/brackets_link.svg');
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -22px;
    }

    &::after {
      content: url('@/assets/images/brackets_link.svg');
      position: absolute;
      bottom: 50%;
      right: 0;
      transform: rotate(180deg);
      margin-bottom: -23px;
    }

  }

  @media screen and (max-width:600px) {

    body {

      .wrapper {

        padding-left: 0px;
        padding-right: 0px;

      }

      main {

        width: 92%;

      }

    }

  }


  .columns {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .col {

      width: 43%;

    }

    @media screen and (max-width:900px) {

      flex-direction: column;

      .col {

        width: 90%;
        margin-left: auto;
        margin-right: auto;        

      }

    }    

  }

  .summary {

    margin-bottom: 4vh;

    h2 {

      @include font(2.3em, 300, 1.2, $text-font-1, $text-color);
      text-transform: uppercase;
      letter-spacing: 0.25em;

      strong {
        font-weight: 700;
      }

      @media screen and (max-width:1100px) {

        font-size: 2em;

      }

      @media screen and (max-width:900px) {

        font-size: 2.3em;

      }

      @media screen and (max-width:500px) {

        font-size: 7vw;

      }      

    }

    ul {

      list-style-type: none;
      margin: 0 0 2em;
      padding: 0;

      li {

        @include font(1.25em, 300, 1.4, $text-font-1, $text-color);
        border-bottom: 1px solid #dadadd;
        padding: 1em 0;

        &:last-child {
          border-bottom: none;
        }

      }

    }

  }


</style>
