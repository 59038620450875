import { createRouter, createWebHistory } from 'vue-router';
//import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    props: { viewId: 'home'},
  },
  {
    path: '/kontakt',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
    props: { viewId: 'contact'},
  },
  {
    path: '/oferta/',
    name: 'offer',
    children: [
      {
        path: 'aktualizacja-stron-www',
        name: 'maintenance',
        component: () => import('../views/MaintenanceView.vue'),
        props: { viewId: 'maintenance'},
      },
      {
        path: 'strony-www',
        name: 'webdevelopment',
        component: () => import('../views/WebdevelopmentView.vue'),
        props: { viewId: 'webdevelopment'},
      }
    ],    
  },
  {
    path: '/realizacje',
    name: 'realizations',
    component: () => import('../views/RealizationsView.vue'),
    props: { viewId: 'realizations'},
  },  
  {
    path: '/polityka-prywatnosci',
    name: 'privacypolicy',
    component: () => import('../views/PrivacyPolicyView.vue'),
    props: { viewId: 'privacypolicy'},
  },
 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  //console.log(to);
  window.scrollTo({top: 0, behavior: 'smooth'});
})

export default router;
