<template>

<!--   <div> -->

  <header ref="header" :class='[{fixed: fixedHeader}, {invieport: headerInViewport}]' >

    <div class="wrapper" :class='{ mobileNavOn: mobileNav }'>

      <router-link to="/" title="Netergo - usługi wsparcia i realizacji stron www"><LogoNetergo /></router-link>

      <nav>

        <ul>          
          <li>
            <span>usługi</span>
            <ul>
              <li><router-link to="/oferta/aktualizacja-stron-www" title="Zobacz ofertę wsparcia i aktualizacji stron www">aktualizacja stron www</router-link></li>
              <li><router-link to="/oferta/strony-www" title="Zobacz ofertę projektowania i realizacji stron www">strony internetowe</router-link></li>
            </ul>
          </li>          
          <li><router-link to="/realizacje" title="Zobacz nasze realizacje">realizacje</router-link></li>
          <li><router-link to="/kontakt" title="Masz pytania? Skontaktuj się z nami">kontakt</router-link></li>
        </ul>

      </nav>

      <p id="mobileNavButton">
        <span @click='toggleNavMobile'><span></span></span>
      </p>

    </div>

  </header>

  <MobileNav :mobileNavOn='mobileNav' />

<!--   </div> -->

</template>

<script>

  import LogoNetergo from '@/components/LogoNetergo.vue';
  import MobileNav from '@/components/MobileNav.vue';
  import { ref, watch, onMounted, onUnmounted } from 'vue';  
  import { useElementVisibility } from '@vueuse/core'

  export default {

    data() {

      return {

        mobileNav: false,
        //viewId: '',
        fixedHeader: false,
        viewPortHeight: window.innerHeight,

      }

    },

    name: 'PageHeader',

    components: {
      MobileNav,      
      LogoNetergo,
    },

    setup() {

      const header = ref();
      const headerInViewport = useElementVisibility(header);
      //let fixedHeader = ref(false);

      //watch(headerInViewport, () => {                
        //fixedHeader.value = headerInViewport.value ? false : true;
        //console.log(headerInViewport.value);
      //})

      //fixedHeader = true;

      return {
        header,
        headerInViewport,
        //fixedHeader,
      }

    },

    watch: {
      '$route': function(){
        this.mobileNav = false;
      },
    }, 

    methods: {

      toggleNavMobile() {
        this.mobileNav = (this.mobileNav)?false:true;
      },

      getScrollTop() {
        return window.pageYOffset || document.documentElement.scrollTop
      },

      toggleFixedHeader() {        
        this.fixedHeader = (window.pageYOffset > this.viewPortHeight)
      },

    },  

    mounted() {

      window.onscroll = () => { 
        this.toggleFixedHeader();
      };

      window.onresize = () => {
        this.toggleFixedHeader();
      };

      

    },

    /* computed: {
          headerPosition() {
            return this.headerPos;
          },
        },    */

  };


</script> 

<style lang="scss">


  .contact header,
  .realizations header {

    .logoNetergo {

      path {

        fill: $lead-color !important;

      }    

    }  

    .wrapper nav ul { 

      li > a, li > span {
        
        color: $lead-color;

        &::after {
          background: $lead-color;
        }

      }

    }

  }


  header {

    max-width: $layout-max-width;
    width: 96%;
    margin: 0 auto;
    min-width: $layout-min-width;
    height: 70px;
    z-index: 999;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-70px);
    opacity: 0;

    &.invieport {
      opacity: 1;
      transform: translateX(-50%) translateY(4vh);
    }

    &.fixed {

      position: fixed;
      background-color: #fff;
      top: 0 !important;
      transform: translateX(-50%) translateY(0);
      opacity: 1;
      -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
      -moz-transition: opacity 0.2s, -moz-transform 0.2s;
      transition: opacity 0.2s, transform 0.2s;          
      -webkit-box-shadow: 0px 0px 57px -24px rgba(66, 68, 90, 0.25);
      -moz-box-shadow: 0px 0px 57px -24px rgba(66, 68, 90, 0.25);
      box-shadow: 0px 0px 57px -24px rgba(66, 68, 90, 0.25);      

      .wrapper {

        nav {

          ul {

            li {

              & > a, & > span {

                color: $lead-color;

                &::after {
                  background: $lead-color-rgb;
                }                

              }

            }

          }

        }

      }

    }

    .wrapper {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: inherit;

      nav {

        padding: 0;
        margin: 0;

        ul {

          list-style-type: none;
          padding: 0;
          margin: 0;

          ul {

            display: none;
            opacity: 0;
            position: absolute;
            top: 0.8em;
            left: 0;            
            margin: 0;
            margin-top: 30px;
            flex-direction: column;
            -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
            -moz-transition: opacity 0.2s, -moz-transform 0.2s;
            transition: opacity 0.2s, transform 0.2s;
            -webkit-box-shadow: 0px 24px 25px -20px rgba(66, 68, 90, 0.25);
            -moz-box-shadow: 0px 24px 25px -20px rgba(66, 68, 90, 0.25);
            box-shadow: 0px 24px 25px -20px rgba(66, 68, 90, 0.25);

            li {

              padding: 0;
              margin: 0;

              a {

                padding: 15px 30px;
                background-color: $layout-color-1;
                @include font(0.8em, 500, 1, $text-font-1, $lead-color);
                text-decoration: none;
                text-transform: uppercase;
                white-space: nowrap;
                display: block;
                -webkit-transition: background-color 0.35s, -webkit-transform 0.35s;
                -moz-transition: background-color 0.35s, -moz-transform 0.35s;
                transition: background-color 0.35s, transform 0.35s;

                &::after {
                  content: initial;
                }

                &:hover {
                  background-color: $layout-color-2;
                }

              }

            }

          }

          li {

            float: left;
            margin-left: 50px;
            position: relative;        

            @media screen and (max-width:800px) {

              margin-left: 40px;

            }  

            & > a, & > span {

              @include font(1em, 600, 1, $text-font-1, #fff);
              text-decoration: none;
              text-transform: uppercase;
              letter-spacing: 0.1em;
              position: relative;
              padding: 22px 0 24px;

              &::after {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 2px;
                background: #fff;
                content: '';
                opacity: 0;
                -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
                -moz-transition: opacity 0.2s, -moz-transform 0.2s;
                transition: opacity 0.2s, transform 0.2s;
                -webkit-transform: translateY(0px);
                -moz-transform: translateY(0px);
                transform: translateY(0px);
              }

              @media screen and (max-width:800px) {

                font-size: 0.9em;

              }              

            }

            & a.router-link-exact-active::after, &:hover span::after, &:hover > a::after {

              opacity: 1;
              -webkit-transform: translateY(-15px);
              -moz-transform: translateY(-15px);
              transform: translateY(-15px);

            }

            &:hover {

              cursor: pointer;

              ul {

                display: flex;
                opacity: 1;

              }

            }

          }

        }

      }

    }

    #mobileNavButton {

      display: none;

      span {

        display: block;
        height: 35px;
        width: 35px;
        text-align: center;
        line-height: 28px;

        span {

          height: 2px;
          width: 25px;
          background-color: #333;
          display: inline-block;
          position: relative;
          
          &::before, &::after {

            content: '';
            height: 2px;
            width: 25px;
            background-color: #333;
            position: absolute;
            left: 0;
            top: 0;
            transition: transform 0.25s ease-in-out;

          }

          &::before {
            transform: translateY(-7px) rotate(0deg);
          }

          &::after {
            transform: translateY(7px) rotate(0deg);
          }
          
        }

      }

    } 

    .mobileNavOn #mobileNavButton span {

      background-color: initial;

      &::before {
        transform: translateY(0) rotate(-45deg);
      }

      &::after {
        transform: translateY(0) rotate(45deg);
      }

    }      

    @media screen and (max-width:600px) {

      & {

        width: 100%;
        top: 0 !important;
        transform: translateX(-50%) translateY(0) !important;
        position: fixed;
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 57px 14px rgba(66, 68, 90, 0.15);
        -moz-box-shadow: 0px 0px 57px 14px rgba(66, 68, 90, 0.15);
        box-shadow: 0px 0px 57px 14px rgba(66, 68, 90, 0.15);             

        .wrapper {

          width: 92%;

          nav {

            display: none;

          }

        }

        #mobileNavButton {

          display: block;

        }

      }

    }


  }


</style>