<template>

  <div id="cookiesPopup" ref="cookiesPopup" :class='{on: cookiesPopupOn}'>

    <p>Strona korzysta z plików cookie w celu realizacji usług zgodnie z Polityką prywatności. Możesz określić warunki przechowywania lub dostępu do cookie w Twojej przeglądarce.</p>
    <button @click="closeCookiesPopup('reject')" title="Zamknij informację o plikach cookies" class="reject">odrzucam
      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg> -->
    </button>

    <button @click="closeCookiesPopup('accept')" title="Zamknij informację o plikach cookies" class="accept">akceptauję</button>

  </div>


</template>


<script>

  import { ref, computed, onMounted, onUnmounted } from 'vue';
  import { useCookies } from "vue3-cookies";
  import { optIn, optOut } from 'vue-gtag';

  export default {

    inject: ['gtag'],

    name: 'CookiesConsent',

    setup() {

      const cookiesPopup = ref();
      const { cookies } = useCookies();
      let cookiesPopupOn = ref(false);

      const closeCookiesPopup = function(opt) {

        switch(opt){
          case 'reject': 
            cookies.set("gdpr-consent", false, "14d");
            optOut();
            break;
          case 'accept':
            cookies.set("gdpr-consent", true, "14d");
            optIn();
            break;
        }
        
        cookiesPopupOn.value = false;

      }

      onMounted(() => {

        if (!cookies.get("gdpr-consent")) {
          cookiesPopupOn.value = true;
        }

      });

      return {

        closeCookiesPopup,
        cookiesPopup,
        cookiesPopupOn,

      }

    },

    mounted() {
      //console.log(gtag)
    },

  }


</script>


<style lang="scss">

  #cookiesPopup {

    max-width: $layout-max-width;
    width: 96%;
    min-width: 240px;
    bottom: 0;
    margin: 0 auto;
    position: fixed;
    background-color: $background-color;
    text-align: center;
    z-index: 997;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    -webkit-transition: -webkit-transform 0.2s;
    -moz-transition: -moz-transform 0.2s;
    transition: transform 0.2s;    
    @include font(0.9em, 300, 1.3, $text-font-1, $text-color);
    
    &.on {
      transform: translateX(-50%) translateY(0);
    }

    p {      

    }

    /* button {
    
          background: none;
          border: none;
          padding: 0;
          margin: 0 0 1em;
          cursor: pointer;
    
        } */

    button {

      border: 0;
      cursor: pointer;
      padding: 7px 15px;
      @include font(0.9em, 500, 1, $text-font-1, $text-color);
      margin-bottom: 20px;

      &.accept {
        background-color: $lead-color;
        color: #fff;
      }

      &.reject {
        background: #fff;
      }

    }



    @media screen and (max-width:600px) {

      width: 92%;

    }

  }

</style>