<template>

  <nav id="mobileNav">

    <ul>
      <li><router-link to="/oferta/aktualizacja-stron-www">aktualizacja stron www</router-link></li>
      <li><router-link to="/oferta/strony-www">strony internetowe</router-link></li>
      <li><router-link to="/realizacje" title="Zobacz nasze realizacje">realizacje</router-link></li>
      <li><router-link to="/kontakt">kontakt</router-link></li>
    </ul>

  </nav>

</template>

<script>

export default {

  name: 'MobileNav',

  props: {
    mobileNavOn: {
      type: Boolean,
      default: false,
    },
  },
  
  computed: {
    mobileNavPosition() {
      return (this.mobileNavOn)?'0%':'-100%'
    },
  },

};

</script> 

<style lang="scss" scoped>

  nav#mobileNav {
    
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vW;
    height: 100vh;
    background: #fff;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 998;

    ul {

      margin: 0;
      padding: 0 10%;
      list-style-type: none;

      li {

        margin-bottom: 1.5em;
        margin-left: 0;
        float: none;

      }

      a, span {

        @include font(1.6em, 600, 1.3, $text-font-1, $lead-color);
        text-decoration: none;

      }

    }

    @media screen and (max-width:600px) {

      & {

        transform: translateX(v-bind(mobileNavPosition));

      }

    }

  }


</style>